/** @format */

import React, { useState } from "react";
import Layout from "../components/Layout";
import { Row, Col, Form, Button } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { emailValidator } from "../utils/validaciones";
import { navigate } from "gatsby-link";

export default function Registro() {
  const [validacion, setValidacion] = useState("");

  const [formularioError, setFormularioError] = useState({
    usuario: false,
    password: false,
  });

  const [formulario, setFormulario] = useState({
    usuario: "",
    password: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    const formulario_login = document.getElementById("login_formulario");
    if (formulario_login.checkValidity() === false) {
      setValidacion("El formulario se encuentra incompleto o con errores.");
    } else {
      setValidacion("");
      fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/user/recover`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          usuario: formulario.usuario,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            navigate("/login/");
          } else {
            setValidacion(await response.text());
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const hadleChange = (event) => {
    setValidacion("");
    if (event.target.name === "usuario") {
      setFormularioError({ ...formularioError, usuario: !emailValidator.test(event.target.value) });
    }
    setFormulario({ ...formulario, [event.target.name]: event.target.value });
  };

  return (
    <Layout>
      <Row className="mt-5 me-lg-5 mb-lg-5 shadow" style={{ backgroundColor: "#90C0F0" }}>
        <Col className="p-4 p-md-5" lg={7}>
          <div className="px-md-5 px-lg-0 px-xl-5">
            <StaticImage src="../images/ondas-agua.png" width={110} className="mb-3" alt="" />
            <h1 className="registro-subtitulo mb-4">Recupera tu contraseña</h1>
            <Form id="login_formulario" onSubmit={handleSubmit}>
              <span style={{ color: "red", fontWeight: "bold" }}>{validacion}</span>
              <Form.Group className="mb-3" controlId="txtEmail">
                <Form.Control
                  required={true}
                  onChange={hadleChange}
                  name="usuario"
                  type="email"
                  placeholder="Email"
                  isInvalid={formularioError.usuario}
                />
                <Form.Control.Feedback type="invalid">
                  Ingresa un correo valido. Ej: tucorreo@direccion.com{" "}
                </Form.Control.Feedback>
              </Form.Group>
              <Row className="mb-4 text-center">
                <Col>
                  <Button type="submit" onClick={handleClick} className="btn-blanco float-end">
                    Enviar
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col className="p-0" lg={5}>
          <StaticImage
            src="../images/tomando-foto-comida.png"
            alt=""
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
}
